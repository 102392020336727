module.exports = {
  locales: [
    'default',
    'ja',
    'en',
    'af',
    'ca',
    'de',
    'es',
    'fil',
    'gu',
    'id',
    'lt',
    'mr',
    'ne',
    'pa',
    'ro',
    'sr',
    'te',
    'uk',
    'zh',
    'bg',
    'cs',
    'el',
    'eu',
    'fr',
    'hi',
    'is',
    'kn',
    'lv',
    'ms',
    'nl',
    'pl',
    'ru',
    'sv',
    'th',
    'vi',
    'bn',
    'da',
    'fi',
    'gl',
    'hu',
    'it',
    'ko',
    'ml',
    'my',
    'no',
    'pt',
    'sk',
    'ta',
    'tr',
    'es-ES',
    'zh-HK',
    'km',
  ],
  defaultLocale: 'default',
  pages: {
    '*': ['common', 'error', 'language'],
    'rgx:^/\\[organization\\]/\\[portal\\]/admin': ['adminCommon'],
    'rgx:^/\\[organization\\]/settings': ['adminCommon'],
    '/login': ['login'],
    '/vb-login': ['login'],
    '/signup': ['signup'],
    '/password-resets/[resetToken]': ['passwordResets'],
    '/password-resets/send-email': ['passwordResetSendEmail'],
    '/redirect': ['redirect'],
    '/system/announcements': ['announcement'],
    '/[organization]': ['masterPortal'],
    '/[organization]/[portal]': ['top'],
    '/[organization]/[portal]/a/[id]': ['article'],
    '/[organization]/[portal]/admin/article': [
      'adminArticleList',
      'adminArticleEdit',
      'adminArticleVideoBrainEmbedContent',
      'adminArticleAiMovieEmbedContent',
      'adminMoviesNew',
      'article',
    ],
    '/[organization]/[portal]/admin/article/new': [
      'adminArticleEdit',
      'adminArticleVideoBrainEmbedContent',
      'adminArticleAiMovieEmbedContent',
    ],
    '/[organization]/[portal]/admin/article/[id]': [
      'adminArticleEdit',
      'adminArticleVideoBrainEmbedContent',
      'adminArticleAiMovieEmbedContent',
    ],
    '/[organization]/[portal]/admin/article/sort': ['adminArticleSort'],
    '/[organization]/[portal]/admin/approval-flow': ['adminApprovalFlow'],
    '/[organization]/[portal]/admin/movies': ['adminMovies', 'adminMoviesNew'],
    '/[organization]/[portal]/admin/movies/[id]': ['adminMovie', 'adminMoviesNew'],
    '/[organization]/[portal]/admin/movies/new': ['adminMoviesNew'],
    '/[organization]/[portal]/admin/movies/[id]/edit': ['adminMoviesNew'],
    '/[organization]/[portal]/admin/category': ['adminCategory'],
    '/[organization]/[portal]/admin/courses': ['adminCourse'],
    '/[organization]/[portal]/admin/courses/new': ['adminCourseEdit', 'adminExam'],
    '/[organization]/[portal]/admin/courses/[id]/edit': ['adminCourseEdit', 'adminExam'],
    '/[organization]/[portal]/admin/design': ['adminDesign'],
    '/[organization]/[portal]/admin/exams': ['adminExam'],
    '/[organization]/[portal]/admin/exams/new': ['adminExamEdit'],
    '/[organization]/[portal]/admin/exams/[id]': ['adminExamEdit'],
    '/[organization]/[portal]/admin/insight/article': ['adminArticleAnalysisList'],
    '/[organization]/[portal]/admin/insight/article/[id]': ['adminArticleInsightDetailList'],
    '/[organization]/[portal]/admin/insight/courses': ['adminCourseInsight'],
    '/[organization]/[portal]/admin/insight/courses/[courseId]': ['adminCourseInsightDetail'],
    '/[organization]/[portal]/admin/insight/courses/[courseId]/users/[id]': ['adminCourseInsight'],
    '/[organization]/[portal]/admin/insight/download': ['adminInsightDownload'],
    '/[organization]/[portal]/admin/insight/exams': ['adminExamInsight'],
    '/[organization]/[portal]/admin/insight/exams/[id]': ['adminExamInsightDetail'],
    '/[organization]/[portal]/admin/insight/exams/[id]/users/[userId]': [
      'adminExamInsightByUser',
      'adminExamInsightDetail',
    ],
    '/[organization]/[portal]/admin/insight/surveys': ['adminSurveyInsight'],
    '/[organization]/[portal]/admin/insight/user': ['adminUserAnalysisList'],
    '/[organization]/[portal]/admin/insight/user/[id]': ['adminUserAnalysis', 'adminExamInsightDetail'],
    '/[organization]/[portal]/admin/language': ['adminLanguage'],
    '/[organization]/[portal]/admin/notification': ['adminNotification'],
    // NOTE: 言語設定ページをこのURLのページに統合したので、jsonを一部許容している
    '/[organization]/[portal]/admin/portal-settings': ['adminPortalSettings', 'adminLanguage'],
    '/[organization]/[portal]/admin/surveys': ['adminSurvey'],
    '/[organization]/[portal]/admin/surveys/new': ['adminSurveyEdit'],
    '/[organization]/[portal]/admin/surveys/[id]': ['adminSurveyEdit'],
    '/[organization]/[portal]/admin/tag': ['adminTag'],
    '/[organization]/[portal]/admin/tasks': ['adminTask'],
    '/[organization]/[portal]/admin/tasks/new': ['adminTaskEdit', 'adminCourse'],
    '/[organization]/[portal]/admin/tasks/[id]': ['adminTaskDetail'],
    '/[organization]/[portal]/admin/tasks/[id]/edit': ['adminTaskEdit', 'adminCourse'],
    '/[organization]/[portal]/admin/tasks/[id]/users/[userId]': [
      'adminCourseInsight',
      'adminTaskEdit',
      'adminTaskDetail',
      'adminTaskInsight',
    ],
    '/[organization]/[portal]/admin/user-list': ['adminUserList'],
    '/[organization]/[portal]/admin/user-list/new': ['adminUserListNew'],
    '/[organization]/[portal]/boards': [
      'board',
      'adminArticleEdit',
      'adminArticleVideoBrainEmbedContent',
      'adminCommon',
    ],
    '/[organization]/[portal]/categories/[id]': ['category'],
    '/[organization]/[portal]/categories/[id]/articles': ['category'],
    '/[organization]/[portal]/courses': ['course'],
    '/[organization]/[portal]/courses/[courseId]': ['course'],
    '/[organization]/[portal]/courses/[courseId]/course-items/[id]': ['exam', 'article'],
    '/[organization]/[portal]/courses/[courseId]/course-items/[id]/exam-results/[examResultId]': ['exam'],
    '/[organization]/[portal]/exams/[examId]': ['exam'],
    '/[organization]/[portal]/exams/[examId]/results/[id]': ['exam'],
    '/[organization]/[portal]/history': ['history'],
    '/[organization]/[portal]/mylist': ['myList'],
    '/[organization]/[portal]/search': ['search'],
    '/[organization]/[portal]/surveys/[id]': ['survey'],
    '/[organization]/[portal]/tags/[id]': ['tag'],
    '/[organization]/settings/contract': ['contract'],
    '/[organization]/settings/usage-summary': ['usageSummary'],
    '/[organization]/settings/user-info': ['userInfo'],
    '/[organization]/settings/users': ['organizationUsers'],
    '/[organization]/settings/users/invitation': ['userInvitation'],
    '/[organization]/settings/user-groups': ['userGroup', 'userGroupList'],
    '/[organization]/settings/user-groups/[id]': ['userGroup', 'userGroupDetail'],
  },
  defaultNS: 'common',
  loadLocaleFrom: (lang, ns) => import(`./src/locales/${lang}/${ns}.json`).then(m => m.default),
};
