/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Empty } from "../google/protobuf/empty";

export const protobufPackage = "organizationService";

export interface GetOrganizationResponse {
  name: string;
}

export interface GetOrganizationMovieUsageRequest {
  organizationName: string;
}

export interface GetOrganizationPlanPropertiesRequest {
  organizationName: string;
}

export interface GetOrganizationPortalUsageRequest {
  organizationName: string;
}

export interface GetOrganizationMovieUsageResponse {
  movieUsedMinutes: number;
  movieLimitMinutes: number;
}

export interface GetOrganizationPlanPropertiesResponse {
  /** 閲覧ユーザー数 */
  userLimit: number;
  /** 編集ユーザー数 */
  adminLimit: number;
  /**
   * 動画のアップロード容量(上限)
   *
   * @deprecated
   */
  movieLimitMinutes: number;
  /** 公開可能ポータル数 */
  portalLimit: number;
  /** 動画のアップロード容量(上限) */
  uploadedVideoLimitMinutes: number;
  /** 動画配信時間(上限) */
  monthlyStreamingLimitMinutes: number;
  /** AI記事生成利用可否 */
  aiGenerateArticleAvailable: boolean;
  /** 多言語利用可否 */
  i18nAvailable: boolean;
}

export interface GetOrganizationPortalUsageResponse {
  /**
   * 動画のアップロード容量 (アップロード済)
   *
   * @deprecated
   */
  movieUsedMinutes: number;
  /**
   * 動画のアップロード容量 (上限)
   *
   * @deprecated
   */
  movieLimitMinutes: number;
  /**
   * 動画のアップロード容量 (内訳)
   *
   * @deprecated
   */
  movieMinutesBreakdowns: PortalBreakdown[];
  /** 閲覧ユーザー (発行済) */
  userUsed: number;
  /** 閲覧ユーザー (上限) */
  userLimit: number;
  /** 閲覧ユーザー (内訳) */
  userBreakdowns: PortalBreakdown[];
  /** 管理ユーザー (発行済) */
  adminUsed: number;
  /** 管理ユーザー (上限) */
  adminLimit: number;
  /** 管理ユーザー (内訳) */
  adminBreakdowns: PortalBreakdown[];
  /** ポータル数 (発行済) */
  portalUsed: number;
  /** ポータル数 (上限) */
  portalLimit: number;
  /** ポータル (内訳) */
  portalBreakdowns: string[];
  /** 動画のアップロード容量 (アップロード済) */
  uploadedVideoUsedMinutes: number;
  /** 動画のアップロード容量 (上限) */
  uploadedVideoLimitMinutes: number;
  /** 動画のアップロード容量 (内訳) */
  uploadedVideoMinutesBreakdowns: PortalBreakdown[];
  /** 動画配信時間 (アップロード済) */
  monthlyStreamingUsedMinutes: number;
  /** 動画配信時間 (上限) */
  monthlyStreamingLimitMinutes: number;
  /** 動画配信時間 (内訳) */
  monthlyStreamingMinutesBreakdowns: PortalBreakdown[];
}

export interface PortalBreakdown {
  displayName: string;
  value: number;
}

function createBaseGetOrganizationResponse(): GetOrganizationResponse {
  return { name: "" };
}

export const GetOrganizationResponse = {
  fromJSON(object: any): GetOrganizationResponse {
    return {
      name: isSet(object.name) ? String(object.name) : "",
    };
  },

  toJSON(message: GetOrganizationResponse): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GetOrganizationResponse>, I>>(
    object: I
  ): GetOrganizationResponse {
    const message = createBaseGetOrganizationResponse();
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseGetOrganizationMovieUsageRequest(): GetOrganizationMovieUsageRequest {
  return { organizationName: "" };
}

export const GetOrganizationMovieUsageRequest = {
  fromJSON(object: any): GetOrganizationMovieUsageRequest {
    return {
      organizationName: isSet(object.organizationName)
        ? String(object.organizationName)
        : "",
    };
  },

  toJSON(message: GetOrganizationMovieUsageRequest): unknown {
    const obj: any = {};
    message.organizationName !== undefined &&
      (obj.organizationName = message.organizationName);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<GetOrganizationMovieUsageRequest>, I>
  >(object: I): GetOrganizationMovieUsageRequest {
    const message = createBaseGetOrganizationMovieUsageRequest();
    message.organizationName = object.organizationName ?? "";
    return message;
  },
};

function createBaseGetOrganizationPlanPropertiesRequest(): GetOrganizationPlanPropertiesRequest {
  return { organizationName: "" };
}

export const GetOrganizationPlanPropertiesRequest = {
  fromJSON(object: any): GetOrganizationPlanPropertiesRequest {
    return {
      organizationName: isSet(object.organizationName)
        ? String(object.organizationName)
        : "",
    };
  },

  toJSON(message: GetOrganizationPlanPropertiesRequest): unknown {
    const obj: any = {};
    message.organizationName !== undefined &&
      (obj.organizationName = message.organizationName);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<GetOrganizationPlanPropertiesRequest>, I>
  >(object: I): GetOrganizationPlanPropertiesRequest {
    const message = createBaseGetOrganizationPlanPropertiesRequest();
    message.organizationName = object.organizationName ?? "";
    return message;
  },
};

function createBaseGetOrganizationPortalUsageRequest(): GetOrganizationPortalUsageRequest {
  return { organizationName: "" };
}

export const GetOrganizationPortalUsageRequest = {
  fromJSON(object: any): GetOrganizationPortalUsageRequest {
    return {
      organizationName: isSet(object.organizationName)
        ? String(object.organizationName)
        : "",
    };
  },

  toJSON(message: GetOrganizationPortalUsageRequest): unknown {
    const obj: any = {};
    message.organizationName !== undefined &&
      (obj.organizationName = message.organizationName);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<GetOrganizationPortalUsageRequest>, I>
  >(object: I): GetOrganizationPortalUsageRequest {
    const message = createBaseGetOrganizationPortalUsageRequest();
    message.organizationName = object.organizationName ?? "";
    return message;
  },
};

function createBaseGetOrganizationMovieUsageResponse(): GetOrganizationMovieUsageResponse {
  return { movieUsedMinutes: 0, movieLimitMinutes: 0 };
}

export const GetOrganizationMovieUsageResponse = {
  fromJSON(object: any): GetOrganizationMovieUsageResponse {
    return {
      movieUsedMinutes: isSet(object.movieUsedMinutes)
        ? Number(object.movieUsedMinutes)
        : 0,
      movieLimitMinutes: isSet(object.movieLimitMinutes)
        ? Number(object.movieLimitMinutes)
        : 0,
    };
  },

  toJSON(message: GetOrganizationMovieUsageResponse): unknown {
    const obj: any = {};
    message.movieUsedMinutes !== undefined &&
      (obj.movieUsedMinutes = Math.round(message.movieUsedMinutes));
    message.movieLimitMinutes !== undefined &&
      (obj.movieLimitMinutes = Math.round(message.movieLimitMinutes));
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<GetOrganizationMovieUsageResponse>, I>
  >(object: I): GetOrganizationMovieUsageResponse {
    const message = createBaseGetOrganizationMovieUsageResponse();
    message.movieUsedMinutes = object.movieUsedMinutes ?? 0;
    message.movieLimitMinutes = object.movieLimitMinutes ?? 0;
    return message;
  },
};

function createBaseGetOrganizationPlanPropertiesResponse(): GetOrganizationPlanPropertiesResponse {
  return {
    userLimit: 0,
    adminLimit: 0,
    movieLimitMinutes: 0,
    portalLimit: 0,
    uploadedVideoLimitMinutes: 0,
    monthlyStreamingLimitMinutes: 0,
    aiGenerateArticleAvailable: false,
    i18nAvailable: false,
  };
}

export const GetOrganizationPlanPropertiesResponse = {
  fromJSON(object: any): GetOrganizationPlanPropertiesResponse {
    return {
      userLimit: isSet(object.userLimit) ? Number(object.userLimit) : 0,
      adminLimit: isSet(object.adminLimit) ? Number(object.adminLimit) : 0,
      movieLimitMinutes: isSet(object.movieLimitMinutes)
        ? Number(object.movieLimitMinutes)
        : 0,
      portalLimit: isSet(object.portalLimit) ? Number(object.portalLimit) : 0,
      uploadedVideoLimitMinutes: isSet(object.uploadedVideoLimitMinutes)
        ? Number(object.uploadedVideoLimitMinutes)
        : 0,
      monthlyStreamingLimitMinutes: isSet(object.monthlyStreamingLimitMinutes)
        ? Number(object.monthlyStreamingLimitMinutes)
        : 0,
      aiGenerateArticleAvailable: isSet(object.aiGenerateArticleAvailable)
        ? Boolean(object.aiGenerateArticleAvailable)
        : false,
      i18nAvailable: isSet(object.i18nAvailable)
        ? Boolean(object.i18nAvailable)
        : false,
    };
  },

  toJSON(message: GetOrganizationPlanPropertiesResponse): unknown {
    const obj: any = {};
    message.userLimit !== undefined &&
      (obj.userLimit = Math.round(message.userLimit));
    message.adminLimit !== undefined &&
      (obj.adminLimit = Math.round(message.adminLimit));
    message.movieLimitMinutes !== undefined &&
      (obj.movieLimitMinutes = Math.round(message.movieLimitMinutes));
    message.portalLimit !== undefined &&
      (obj.portalLimit = Math.round(message.portalLimit));
    message.uploadedVideoLimitMinutes !== undefined &&
      (obj.uploadedVideoLimitMinutes = Math.round(
        message.uploadedVideoLimitMinutes
      ));
    message.monthlyStreamingLimitMinutes !== undefined &&
      (obj.monthlyStreamingLimitMinutes = Math.round(
        message.monthlyStreamingLimitMinutes
      ));
    message.aiGenerateArticleAvailable !== undefined &&
      (obj.aiGenerateArticleAvailable = message.aiGenerateArticleAvailable);
    message.i18nAvailable !== undefined &&
      (obj.i18nAvailable = message.i18nAvailable);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<GetOrganizationPlanPropertiesResponse>, I>
  >(object: I): GetOrganizationPlanPropertiesResponse {
    const message = createBaseGetOrganizationPlanPropertiesResponse();
    message.userLimit = object.userLimit ?? 0;
    message.adminLimit = object.adminLimit ?? 0;
    message.movieLimitMinutes = object.movieLimitMinutes ?? 0;
    message.portalLimit = object.portalLimit ?? 0;
    message.uploadedVideoLimitMinutes = object.uploadedVideoLimitMinutes ?? 0;
    message.monthlyStreamingLimitMinutes =
      object.monthlyStreamingLimitMinutes ?? 0;
    message.aiGenerateArticleAvailable =
      object.aiGenerateArticleAvailable ?? false;
    message.i18nAvailable = object.i18nAvailable ?? false;
    return message;
  },
};

function createBaseGetOrganizationPortalUsageResponse(): GetOrganizationPortalUsageResponse {
  return {
    movieUsedMinutes: 0,
    movieLimitMinutes: 0,
    movieMinutesBreakdowns: [],
    userUsed: 0,
    userLimit: 0,
    userBreakdowns: [],
    adminUsed: 0,
    adminLimit: 0,
    adminBreakdowns: [],
    portalUsed: 0,
    portalLimit: 0,
    portalBreakdowns: [],
    uploadedVideoUsedMinutes: 0,
    uploadedVideoLimitMinutes: 0,
    uploadedVideoMinutesBreakdowns: [],
    monthlyStreamingUsedMinutes: 0,
    monthlyStreamingLimitMinutes: 0,
    monthlyStreamingMinutesBreakdowns: [],
  };
}

export const GetOrganizationPortalUsageResponse = {
  fromJSON(object: any): GetOrganizationPortalUsageResponse {
    return {
      movieUsedMinutes: isSet(object.movieUsedMinutes)
        ? Number(object.movieUsedMinutes)
        : 0,
      movieLimitMinutes: isSet(object.movieLimitMinutes)
        ? Number(object.movieLimitMinutes)
        : 0,
      movieMinutesBreakdowns: Array.isArray(object?.movieMinutesBreakdowns)
        ? object.movieMinutesBreakdowns.map((e: any) =>
            PortalBreakdown.fromJSON(e)
          )
        : [],
      userUsed: isSet(object.userUsed) ? Number(object.userUsed) : 0,
      userLimit: isSet(object.userLimit) ? Number(object.userLimit) : 0,
      userBreakdowns: Array.isArray(object?.userBreakdowns)
        ? object.userBreakdowns.map((e: any) => PortalBreakdown.fromJSON(e))
        : [],
      adminUsed: isSet(object.adminUsed) ? Number(object.adminUsed) : 0,
      adminLimit: isSet(object.adminLimit) ? Number(object.adminLimit) : 0,
      adminBreakdowns: Array.isArray(object?.adminBreakdowns)
        ? object.adminBreakdowns.map((e: any) => PortalBreakdown.fromJSON(e))
        : [],
      portalUsed: isSet(object.portalUsed) ? Number(object.portalUsed) : 0,
      portalLimit: isSet(object.portalLimit) ? Number(object.portalLimit) : 0,
      portalBreakdowns: Array.isArray(object?.portalBreakdowns)
        ? object.portalBreakdowns.map((e: any) => String(e))
        : [],
      uploadedVideoUsedMinutes: isSet(object.uploadedVideoUsedMinutes)
        ? Number(object.uploadedVideoUsedMinutes)
        : 0,
      uploadedVideoLimitMinutes: isSet(object.uploadedVideoLimitMinutes)
        ? Number(object.uploadedVideoLimitMinutes)
        : 0,
      uploadedVideoMinutesBreakdowns: Array.isArray(
        object?.uploadedVideoMinutesBreakdowns
      )
        ? object.uploadedVideoMinutesBreakdowns.map((e: any) =>
            PortalBreakdown.fromJSON(e)
          )
        : [],
      monthlyStreamingUsedMinutes: isSet(object.monthlyStreamingUsedMinutes)
        ? Number(object.monthlyStreamingUsedMinutes)
        : 0,
      monthlyStreamingLimitMinutes: isSet(object.monthlyStreamingLimitMinutes)
        ? Number(object.monthlyStreamingLimitMinutes)
        : 0,
      monthlyStreamingMinutesBreakdowns: Array.isArray(
        object?.monthlyStreamingMinutesBreakdowns
      )
        ? object.monthlyStreamingMinutesBreakdowns.map((e: any) =>
            PortalBreakdown.fromJSON(e)
          )
        : [],
    };
  },

  toJSON(message: GetOrganizationPortalUsageResponse): unknown {
    const obj: any = {};
    message.movieUsedMinutes !== undefined &&
      (obj.movieUsedMinutes = Math.round(message.movieUsedMinutes));
    message.movieLimitMinutes !== undefined &&
      (obj.movieLimitMinutes = Math.round(message.movieLimitMinutes));
    if (message.movieMinutesBreakdowns) {
      obj.movieMinutesBreakdowns = message.movieMinutesBreakdowns.map((e) =>
        e ? PortalBreakdown.toJSON(e) : undefined
      );
    } else {
      obj.movieMinutesBreakdowns = [];
    }
    message.userUsed !== undefined &&
      (obj.userUsed = Math.round(message.userUsed));
    message.userLimit !== undefined &&
      (obj.userLimit = Math.round(message.userLimit));
    if (message.userBreakdowns) {
      obj.userBreakdowns = message.userBreakdowns.map((e) =>
        e ? PortalBreakdown.toJSON(e) : undefined
      );
    } else {
      obj.userBreakdowns = [];
    }
    message.adminUsed !== undefined &&
      (obj.adminUsed = Math.round(message.adminUsed));
    message.adminLimit !== undefined &&
      (obj.adminLimit = Math.round(message.adminLimit));
    if (message.adminBreakdowns) {
      obj.adminBreakdowns = message.adminBreakdowns.map((e) =>
        e ? PortalBreakdown.toJSON(e) : undefined
      );
    } else {
      obj.adminBreakdowns = [];
    }
    message.portalUsed !== undefined &&
      (obj.portalUsed = Math.round(message.portalUsed));
    message.portalLimit !== undefined &&
      (obj.portalLimit = Math.round(message.portalLimit));
    if (message.portalBreakdowns) {
      obj.portalBreakdowns = message.portalBreakdowns.map((e) => e);
    } else {
      obj.portalBreakdowns = [];
    }
    message.uploadedVideoUsedMinutes !== undefined &&
      (obj.uploadedVideoUsedMinutes = Math.round(
        message.uploadedVideoUsedMinutes
      ));
    message.uploadedVideoLimitMinutes !== undefined &&
      (obj.uploadedVideoLimitMinutes = Math.round(
        message.uploadedVideoLimitMinutes
      ));
    if (message.uploadedVideoMinutesBreakdowns) {
      obj.uploadedVideoMinutesBreakdowns =
        message.uploadedVideoMinutesBreakdowns.map((e) =>
          e ? PortalBreakdown.toJSON(e) : undefined
        );
    } else {
      obj.uploadedVideoMinutesBreakdowns = [];
    }
    message.monthlyStreamingUsedMinutes !== undefined &&
      (obj.monthlyStreamingUsedMinutes = Math.round(
        message.monthlyStreamingUsedMinutes
      ));
    message.monthlyStreamingLimitMinutes !== undefined &&
      (obj.monthlyStreamingLimitMinutes = Math.round(
        message.monthlyStreamingLimitMinutes
      ));
    if (message.monthlyStreamingMinutesBreakdowns) {
      obj.monthlyStreamingMinutesBreakdowns =
        message.monthlyStreamingMinutesBreakdowns.map((e) =>
          e ? PortalBreakdown.toJSON(e) : undefined
        );
    } else {
      obj.monthlyStreamingMinutesBreakdowns = [];
    }
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<GetOrganizationPortalUsageResponse>, I>
  >(object: I): GetOrganizationPortalUsageResponse {
    const message = createBaseGetOrganizationPortalUsageResponse();
    message.movieUsedMinutes = object.movieUsedMinutes ?? 0;
    message.movieLimitMinutes = object.movieLimitMinutes ?? 0;
    message.movieMinutesBreakdowns =
      object.movieMinutesBreakdowns?.map((e) =>
        PortalBreakdown.fromPartial(e)
      ) || [];
    message.userUsed = object.userUsed ?? 0;
    message.userLimit = object.userLimit ?? 0;
    message.userBreakdowns =
      object.userBreakdowns?.map((e) => PortalBreakdown.fromPartial(e)) || [];
    message.adminUsed = object.adminUsed ?? 0;
    message.adminLimit = object.adminLimit ?? 0;
    message.adminBreakdowns =
      object.adminBreakdowns?.map((e) => PortalBreakdown.fromPartial(e)) || [];
    message.portalUsed = object.portalUsed ?? 0;
    message.portalLimit = object.portalLimit ?? 0;
    message.portalBreakdowns = object.portalBreakdowns?.map((e) => e) || [];
    message.uploadedVideoUsedMinutes = object.uploadedVideoUsedMinutes ?? 0;
    message.uploadedVideoLimitMinutes = object.uploadedVideoLimitMinutes ?? 0;
    message.uploadedVideoMinutesBreakdowns =
      object.uploadedVideoMinutesBreakdowns?.map((e) =>
        PortalBreakdown.fromPartial(e)
      ) || [];
    message.monthlyStreamingUsedMinutes =
      object.monthlyStreamingUsedMinutes ?? 0;
    message.monthlyStreamingLimitMinutes =
      object.monthlyStreamingLimitMinutes ?? 0;
    message.monthlyStreamingMinutesBreakdowns =
      object.monthlyStreamingMinutesBreakdowns?.map((e) =>
        PortalBreakdown.fromPartial(e)
      ) || [];
    return message;
  },
};

function createBasePortalBreakdown(): PortalBreakdown {
  return { displayName: "", value: 0 };
}

export const PortalBreakdown = {
  fromJSON(object: any): PortalBreakdown {
    return {
      displayName: isSet(object.displayName) ? String(object.displayName) : "",
      value: isSet(object.value) ? Number(object.value) : 0,
    };
  },

  toJSON(message: PortalBreakdown): unknown {
    const obj: any = {};
    message.displayName !== undefined &&
      (obj.displayName = message.displayName);
    message.value !== undefined && (obj.value = Math.round(message.value));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<PortalBreakdown>, I>>(
    object: I
  ): PortalBreakdown {
    const message = createBasePortalBreakdown();
    message.displayName = object.displayName ?? "";
    message.value = object.value ?? 0;
    return message;
  },
};

export interface OrganizationService {
  GetOrganization(request: Empty): Promise<GetOrganizationResponse>;
  GetOrganizationMovieUsage(
    request: GetOrganizationMovieUsageRequest
  ): Promise<GetOrganizationMovieUsageResponse>;
  GetOrganizationPlanProperties(
    request: GetOrganizationPlanPropertiesRequest
  ): Promise<GetOrganizationPlanPropertiesResponse>;
  GetOrganizationPortalUsage(
    request: GetOrganizationPortalUsageRequest
  ): Promise<GetOrganizationPortalUsageResponse>;
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
