export const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

export const ACCEPT_IMAGE_MIME_TYPE = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'] as const;
export const PPTX_MIME_TYPES = [
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-powerpoint',
] as const;

export const SWITCHABLE_RICH_VIEWER_MIME_TYPES = ['application/pdf', ...PPTX_MIME_TYPES] as const;

export const MAX_CATEGORY_DEPTH = 4;

export const TAG_MAX_LENGTH = 20;

export const MAX_LOCAL_FILE_COUNT = 30;

export const IMAGE_FILE_MAX_BYTE_SIZE = 10 * 1024 ** 2;
export const ATTACH_FILE_MAX_BYTE_SIZE = 200 * 1024 ** 2;

export const SUPPORT_EMAIL_ADDRESS = 'ob-support@open8.com';

export const MANUAL_URL = 'https://portal.video-b.com/open8/obsupport';
export const FAQ_INDEX_URL = 'https://videobrain.force.com/obfaq/s/';
export const FAQ_CATEGORY_URL = 'https://videobrain.force.com/obfaq/s/article/ob-category';
export const FAQ_ANALYTICAL_URL = 'https://videobrain.force.com/obfaq/s/article/ob-analytical-data';
export const FAQ_ANALYTICAL_ARTICLE_URL = 'https://videobrain.force.com/obfaq/s/article/ob-analytical-article';
export const FAQ_ANALYTICAL_USER_URL = 'https://videobrain.force.com/obfaq/s/article/ob-analytical-user';
export const FAQ_TAG_URL = 'https://videobrain.force.com/obfaq/s/article/ob-tag';
export const FAQ_THUMBNAIL_URL = 'https://videobrain.force.com/obfaq/s/article/ob-thumbnail-settings';
export const FAQ_OPTIONS_URL = 'https://videobrain.force.com/obfaq/s/article/ob-video-options';
export const FAQ_FILE_SERVICE_URL = 'https://videobrain.force.com/obfaq/s/topic/0TO5i000000gSnFGAU/';
export const TERMS_OF_USE_URL = 'https://video-b.com/term_ob';
export const FAQ_ARTICLE_NOTIFICATION_URL = 'https://open8.my.site.com/obfaq/s/article/ob-article-notification';
export const FAQ_LANGUAGE_URL = 'https://open8.my.site.com/obfaq/s/article/ob-language-settings';
export const FAQ_NOTIFICATION_URL = 'https://open8.my.site.com/obfaq/s/article/slack-notification';
export const FAQ_APPROVAL_FLOW_URL = 'https://open8.my.site.com/obfaq/s/article/ob-approval-flow';
// export const PRIVACY_POLICY_URL = '/404';
// export const RECOMMENDED_ENVIRONMENT_URL = '/404';

export const MOVIE_TIME_STEP = 0.1;
export const CHAPTER_TITLE_MAX_LENGTH = 50;

export const LOCAL_STORAGE_KEY = {
  NEVER_SHOW_CONFIRM_MOVIE_REFLECTION_MODAL: 'neverShowConfirmMovieReflectionModal',
  NEW_ANNOUNCEMENT_DATE: 'newAnnouncement',
  MS_ACCOUNT_SELECTION_DONE: 'msAccountSelectionDone',
  GOOGLE_AUTH_INFO_KEY: 'googleAuthInfo',
  BOX_AUTH_INFO_KEY: 'boxAuthInfo',
} as const;

export const SERVICE_NAME = 'open_brain';

export const APPLE_SUPPORT_URL_FOR_TRACKING_SETTING =
  'https://support.apple.com/ja-jp/guide/safari/sfri40732/mac#:~:text=Mac%E3%81%AESafari%E3%82%A2%E3%83%97%E3%83%AA%E3%82%B1%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3%20%E3%81%A7,%E3%83%87%E3%83%BC%E3%82%BF%E3%81%AF%E5%89%8A%E9%99%A4%E3%81%95%E3%82%8C%E3%81%BE%E3%81%99%E3%80%82';

export const EDGE_SUPPORT_URL_FOR_TRACKING_SETTING =
  'https://support.microsoft.com/ja-jp/microsoft-edge/microsoft-edge-%E3%81%AE%E8%BF%BD%E8%B7%A1%E9%98%B2%E6%AD%A2%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6-5ac125e8-9b90-8d59-fa2c-7f2e9a44d869';

export const CHROME_SUPPORT_URL_FOR_TRACKING_SETTING =
  'https://support.google.com/chrome/answer/2790761?hl=ja&co=GENIE.Platform%3DiOS&oco=1';

/**
 * Open BRAINのSAML SSOログインページURL
 */
export const SAML_SSO_OB_LOGIN_PAGE_URL = `${process.env.NEXT_PUBLIC_PV_API_BASE_URL}/sessions/openbrain/saml/new`;
/**
 * Video BRAINのSAML SSOログインページURL
 */
export const SAML_SSO_VB_LOGIN_PAGE_URL = `${process.env.NEXT_PUBLIC_PV_API_BASE_URL}/sessions/videobrain/saml/new`;

export const AVAILABLE_LANGUAGES = [
  { id: 'ja', name: '日本語' },
  { id: 'af', name: 'Afrikaans' },
  { id: 'ca', name: 'Català' },
  { id: 'de', name: 'Deutsch' },
  { id: 'es-ES', name: 'Español' },
  { id: 'fil', name: 'Filipino' },
  { id: 'gu', name: 'ગુજરાતી' },
  { id: 'id', name: 'Bahasa Indonesia' },
  { id: 'lt', name: 'Lietuvių' },
  { id: 'mr', name: 'मराठी' },
  { id: 'ne', name: 'नेपाली' },
  { id: 'pa', name: 'ਪੰਜਾਬੀ' },
  { id: 'ro', name: 'Română' },
  { id: 'sr', name: 'Српски' },
  { id: 'te', name: 'తెలుగు' },
  { id: 'uk', name: 'Українська' },
  { id: 'zh', name: '中文' },
  { id: 'bg', name: 'Български' },
  { id: 'cs', name: 'Čeština' },
  { id: 'el', name: 'Ελληνικά' },
  { id: 'eu', name: 'Euskara' },
  { id: 'fr', name: 'Français' },
  { id: 'hi', name: 'हिन्दी' },
  { id: 'is', name: 'Íslenska' },
  { id: 'kn', name: 'ಕನ್ನಡ' },
  { id: 'lv', name: 'Latviešu' },
  { id: 'ms', name: 'Bahasa Melayu' },
  { id: 'nl', name: 'Nederlands' },
  { id: 'pl', name: 'Polski' },
  { id: 'ru', name: 'Русский' },
  { id: 'sv', name: 'Svenska' },
  { id: 'th', name: 'ไทย' },
  { id: 'vi', name: 'Tiếng Việt' },
  { id: 'bn', name: 'বাংলা' },
  { id: 'da', name: 'Dansk' },
  { id: 'en', name: 'English' },
  { id: 'fi', name: 'Suomi' },
  { id: 'gl', name: 'Galego' },
  { id: 'hu', name: 'Magyar' },
  { id: 'it', name: 'Italiano' },
  { id: 'ko', name: '한국어' },
  { id: 'ml', name: 'മലയാളം' },
  { id: 'my', name: 'မြန်မာ' },
  { id: 'no', name: 'Norsk' },
  { id: 'pt', name: 'Português' },
  { id: 'sk', name: 'Slovenčina' },
  { id: 'ta', name: 'தமிழ்' },
  { id: 'tr', name: 'Türkçe' },
  { id: 'zh-HK', name: '廣東話' },
  { id: 'km', name: 'ខ្មែរ' },
].sort((a, b) => a.name.localeCompare(b.name)) as { id: LanguageCode; name: string }[];

export const LANGUAGE_CODES: string[] = AVAILABLE_LANGUAGES.map(lang => lang.id);

export type LanguageCode = (typeof LANGUAGE_CODES)[number];
