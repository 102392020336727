import Head from 'next/head';
import type { NextPage } from 'next';

// TODO: OGP、SNSのシェアなどがあれば追加
interface HeadTemplateProps {
  /**
   * Googleの検索エンジンに認識させるURL
   */
  pageCanonicalUrl?: string;
  /**
   * @概要 サイト内の各ページで個別の内容になるように設定すること(全角35文字以下が望ましい)
   */
  pageTitle?: string;
  /**
   * @概要 画面名
   */
  pageName?: string;
  /**
   * @概要 サイト内の各ページで個別の内容になるように設定すること（全角80〜110文字以内が望ましい）
   */
  pageDescription?: string;
  /**
   * 検索を避けるための判定フラグ
   * - 低品質なページや、ユーザーにとって価値のないページの場合「true」
   * - 普通にインデックス、クロールして欲しい場合は「false」
   */
  isNoIndex?: boolean;
}

export const HeadTemplate: NextPage<HeadTemplateProps> = ({
  pageCanonicalUrl,
  pageTitle = '',
  pageName = '',
  pageDescription = '',
  isNoIndex = false,
}) => {
  return (
    <Head>
      <title>{pageName ? `${pageTitle} | ${pageName}` : `${pageTitle}`}</title>
      <meta charSet="UTF-8" />
      <meta name="description" content={pageDescription} />
      <meta name="viewport" content="width=device-width,initial-scale=1.0,maximum-scale=1.0,user-scalable=no"></meta>
      {/* TODO:決まり次第追加 */}
      <meta name="author" content="" />
      <meta name="theme-color" content="" />
      {isNoIndex && <meta name="robots" content="noindex,nofollow" />}
      <meta property="og:title" content={pageTitle} />
      <meta property="og:site_name" content={pageTitle} />
      <meta property="og:description" content={pageDescription} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <link rel="canonical" href={pageCanonicalUrl} />
    </Head>
  );
};
