import { v4 as uuid } from 'uuid';
import { LOCAL_STORAGE_KEY } from '~/constants';
import { snakeToCamel } from '~/utils/convertCase';
import { RedirectOption } from '~/utils/domain/externalServiceRedirect/utils';
import { BoxLoginPopupHandler } from './BoxLoginPopupHandler';
import { config } from './config';
import { BoxAuthInfo, BoxAuthResponse } from './model';

const createRedirectOption = (nonce: string): RedirectOption => {
  const separateIndex = document.domain.indexOf('.');
  const [subDomain, domain] = [document.domain.slice(0, separateIndex), document.domain.slice(separateIndex)];

  const isPreviewDeployment = domain === '.vercel-front-development.video-b.com';

  return {
    o: subDomain,
    p: isPreviewDeployment,
    n: nonce,
  };
};

const getAuthInfo = () => {
  const boxAuthInfoJsonString = window.localStorage?.getItem(LOCAL_STORAGE_KEY.BOX_AUTH_INFO_KEY);
  if (!boxAuthInfoJsonString) return null;

  const boxAuthInfoJson = JSON.parse(boxAuthInfoJsonString);
  if (typeof boxAuthInfoJson !== 'object') return null;

  return BoxAuthInfo.fromJson(boxAuthInfoJson);
};

export class BoxAuthClient {
  private static _instance: BoxAuthClient | null = null;
  private _isSignedIn = false;
  private _authInfo: BoxAuthInfo | null = null;
  constructor() {
    const authInfo = getAuthInfo();
    this._isSignedIn = !!authInfo;
    this._authInfo = authInfo;
  }

  /**
   * シングルトンなインスタンス
   * windowがない環境では参照できない
   */
  static get instance() {
    if (!BoxAuthClient._instance && typeof window !== 'undefined') {
      BoxAuthClient._instance = new BoxAuthClient();
    }
    return BoxAuthClient._instance ?? null;
  }

  /** サインイン状態 */
  get isSignedIn() {
    return this._isSignedIn;
  }

  /** サインイン情報 */
  get authInfo() {
    return this._authInfo;
  }

  /**
   * サインインを行う
   * サインインを行なってlocalStorageにサインイン情報を保存する
   */
  public async signIn() {
    const nonce = uuid();
    await fetch('/api/nonce', { method: 'PUT', body: nonce });
    const option = createRedirectOption(nonce);
    const params = {
      client_id: config.clientId ?? '',
      response_type: 'code',
      redirect_uri: `${window.location.origin}/redirect`,
      state: JSON.stringify(option),
    };
    const baseUrl = 'https://account.box.com/api/oauth2/authorize';
    const queryString = new URLSearchParams(params).toString();
    const authorizationUrl = `${baseUrl}?${queryString}`;
    try {
      const authCode = await BoxLoginPopupHandler.popupLogin(authorizationUrl);
      await this.requestToken({ authCode });
    } catch (error) {
      throw error;
    }
  }

  /**
   * トークンのリフレッシュを行う
   * トークンのリフレッシュを行なって、新しいトークンをlocalStorageに保存する
   */
  public async refreshToken() {
    const boxAuthInfo = getAuthInfo();
    try {
      if (!boxAuthInfo || !boxAuthInfo?.refreshToken) throw new Error('refreshTokenが設定されていません');
      return await this.requestToken({ refreshToken: boxAuthInfo.refreshToken });
    } catch (error) {
      try {
        // トークンのリフレッシュがうまくできなかったときはサインインを行う
        await this.signIn();
        const currentBoxAuthInfo = getAuthInfo();
        if (!currentBoxAuthInfo) throw new Error('サインインに失敗しました。');
        return currentBoxAuthInfo;
      } catch (error) {
        throw error;
      }
    }
  }

  /**
   * サインアウトを行う
   * 現在のトークンを無効化し、localStorageからサインイン情報を削除する
   */
  public async signOut() {
    const boxAuthInfo = getAuthInfo();
    if (boxAuthInfo?.accessToken) {
      try {
        await fetch('/api/box-api-proxy/oauth2/revoke', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            token: boxAuthInfo.accessToken,
          }),
        });
        // すでに期限が切れていたり無効化されているトークンの時も400エラーになるので、レスポンスがokかどうかの検証はせずにlocalStorageを更新する
        this.setAuthInfo(null);
      } catch (error) {
        // TODO: エラーハンドリング
        throw error;
      }
    }
  }

  /**
   * アクセストークンを取得する
   * アクセストークンの期限が切れている場合はリフレッシュも行う
   * @returns アクセストークン
   */
  public async getToken() {
    const boxAuthInfo = getAuthInfo();
    if (!boxAuthInfo) return null;
    if (boxAuthInfo?.accessToken && boxAuthInfo.expiresAt < Date.now()) {
      // リフレッシュが必要な場合
      const currentBoxAuthInfo = await this.refreshToken();
      return currentBoxAuthInfo.accessToken;
    }

    return boxAuthInfo.accessToken;
  }

  /**
   * トークンのリクエストを行う
   * authCodeを利用したトークンのリクエストとrefreshTokenを利用したトークンのリクエストを行える
   * 取得したトークンはlocalStorageに保存される
   * @param param トークンのリクエストに利用するパラメーター
   * @returns 認証情報
   */
  private async requestToken(param: { authCode?: string; refreshToken?: string }) {
    const { authCode, refreshToken } = param;

    try {
      const response = await fetch('/api/box-api-proxy/oauth2/token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          authCode,
          refreshToken,
        }),
      });

      if (response.ok) {
        const json = await response.json();
        const data = BoxAuthResponse.fromJson(snakeToCamel(json));
        const boxAuthInfo: BoxAuthInfo = {
          accessToken: data.accessToken,
          // リフレッシュの余裕を持たせるために数秒引いておく
          expiresAt: Date.now() + (data.expiresIn - 10) * 1000,
          refreshToken: data.refreshToken,
        };
        this.setAuthInfo(boxAuthInfo);
        return boxAuthInfo;
      }
      throw new Error('Boxの認証が失敗しました。');
    } catch (error) {
      throw error;
    }
  }

  /**
   * ログイン情報を更新する
   * @param boxAuthInfo
   */
  private setAuthInfo(boxAuthInfo: BoxAuthInfo | null) {
    if (boxAuthInfo) {
      window.localStorage.setItem(LOCAL_STORAGE_KEY.BOX_AUTH_INFO_KEY, JSON.stringify(boxAuthInfo));
      this._isSignedIn = true;
      this._authInfo = boxAuthInfo;
    } else {
      localStorage?.removeItem(LOCAL_STORAGE_KEY.BOX_AUTH_INFO_KEY);
      this._isSignedIn = false;
      this._authInfo = null;
    }
  }
}
